<template>
  <cs-page>
    <a-result
      status="403"
      title="403"
      sub-title="抱歉！您没有权限访问这个的页面"
    >
      <template #extra>
        <a-button @click="backHomeClick" type="primary">返回首页</a-button>
      </template>
    </a-result>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    backHomeClick() {
      this.$router.replace("/");
    },
  },
};
</script>